<template>
    <page-title
        icon="bi-people"
        title="ユーザ編集"
    >
        <button-back
            :to="{name: 'UserList'}"
            text="一覧へ"
        ></button-back>
    </page-title>

    <template v-if="!loading">
        <section class="section">
            <form @submit.prevent="save">
                <div class="form-group col-md-6 mb-3">
                    <label>アカウントタイプ</label>
                    <read-only :text="user.account_type_display"></read-only>
                </div>

                <div class="form-group col-md-6 mb-3">
                    <label>氏名</label>
                    <template v-if="isGoogleAccount">
                        <read-only :text="user.user_name"></read-only>
                    </template>
                    <template v-else>
                        <form-input
                            v-model="user.user_name"
                            :required="true"
                            maxlength="20"
                        ></form-input>
                        <p class="text-secondary">＊20文字以内</p>
                    </template>
                </div>

                <template v-if="isGoogleAccount">
                    <div class="form-group col-md-10 mb-3">
                        <label>GoogleユーザID</label>
                        <read-only :text="user.google_user_id"></read-only>
                    </div>
                    <div class="form-group col-md-10 mb-3">
                        <label>Googleメールアドレス</label>
                        <read-only :text="user.google_email"></read-only>
                    </div>
                </template>
                <template v-else>
                    <div class="form-group col-md-10 mb-3">
                        <label>ログインID</label>
                        <form-input
                            v-model="user.login_key"
                            :required="true"
                            maxlength="20"
                        ></form-input>
                        <p class="text-secondary">＊20文字以内</p>
                    </div>
                    <div class="form-group col-md-10 mb-3">
                        <label>パスワード</label>
                        <form-input-password
                            v-model="user.password"
                            pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,1000}$"
                        ></form-input-password>
                        <div class="text-danger">＊変更する場合のみ入力</div>
                        <p class="text-secondary">＊大文字を含む英数字8文字以上</p>
                    </div>
                </template>

                <div class="form-group col-md-6 mb-3">
                    <label>ログイン可否</label>
                    <form-select
                        v-model="user.is_available"
                        :options="is_available"
                        empty_option="-- 選択 --"
                        :required="true"
                    ></form-select>
                </div>
                <div class="form-group col-md-8 mb-4">
                    <label>権限</label>
                    <form-checkbox-buttons
                        v-model="user.roles"
                        :options="roles"
                        optional_label_class="border-1"
                    ></form-checkbox-buttons>
                </div>
                <div class="col d-flex justify-content-between">
                    <button-exec-update
                        size="btn-lg"
                    ></button-exec-update>
                    <button-exec-delete
                        v-if="!isGoogleAccount"
                        size="btn-lg"
                        @click="$refs.confirm_remove.show()"
                    ></button-exec-delete>
                </div>
            </form>
        </section>
    </template>

    <confirm-dialog ref="confirm_remove" @ok="remove">
        <p>削除してもよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import User from '@/models/entities/user'
import AccountType from '@/models/enums/account-type';
import IsAvailable from '@/models/enums/is-available';
import Role from '@/models/enums/role';
import PageTitle from '@/components/PageTitle.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import FormCheckboxButtons from '@/components/forms/FormCheckboxButtons';
import FormInput from '@/components/forms/FormInput';
import FormInputPassword from '@/components/forms/FormInputPassword';
import FormSelect from '@/components/forms/FormSelect';
import ReadOnly from '@/components/forms/ReadOnly';

export default {
    name: 'PageUserEdit',
    components: {
        ConfirmDialog,
        PageTitle,
        ButtonBack,
        ButtonExecDelete,
        ButtonExecUpdate,
        FormCheckboxButtons,
        FormInput,
        FormInputPassword,
        FormSelect,
        ReadOnly,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: true,

            //選択肢
            account_types: AccountType.options(),
            is_available: IsAvailable.options(),
            roles: Role.options(),
            AccountType: AccountType,

            //対象ユーザID
            user_id: parseInt(this.$route.params.id, 10),

            //ユーザー情報
            user: new User()
        }
    },
    mounted() {
        this.startScreenLoading();
        this.$http.get("/user/" + this.user_id)
        .then(response => {
            this.user = new User(response.data);
        })
        .finally(() => {
            this.loading = false;
            this.endScreenLoading();
        });
    },
    computed: {
        //Googleアカウントユーザーかどうか
        isGoogleAccount() {
            if (this.user.account_type === AccountType.GOOGLE_ACCOUNT) {
                return true;
            }
            return false;
        }
    },
    methods: {
        save() {
            this.startScreenLoading();
            this.$http.put(`/user/${this.user_id}`, this.user)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/user/${this.user_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.$router.push({name: 'UserList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>

</style>
